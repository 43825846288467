import { defineStore } from 'pinia'
import nuxtStorage from 'nuxt-storage';

export const useQueryParamsStore = defineStore('query-params', {
  state: () => ({
    queryParams: {} as Record<string, string>,
  }),
  persist: true,
  actions: {
    setQueryParams(params: Record<string, string>) {
      this.queryParams = params;
    },
    reset() {
      this.queryParams = {} as Record<string, string>
      nuxtStorage?.sessionStorage?.removeItem('query-params')
    },
  },
  getters: {
    getQueryParams: state => state.queryParams,
  }
});